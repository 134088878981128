// components
import CommonForm from "../Shared/CommonForm";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class FinancialModelSalaryCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = [
            {
                'name': 'title',
                'label': 'Заголовок',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'count',
                'label': 'Количество раз',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 1,
                'type': 'number',
            },
            {
                'name': 'value',
                'label': 'Значение',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 1,
                'type': 'number',
            },
            {
                type: 'submit',
                label: 'Добавить'
            }
        ]
        return {
            modalLabel: 'Добавление инженерного работника',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        await this.props.callBack(data);
        this.setModalOpenFalse()
    }
}

export default FinancialModelSalaryCreate
