import React from "react";
import BaseApi from "../../../utils/BaseApi";
import retrievePermission from "../../../utils/retrievePermission";
import UserSubscriberContext from "../../../context/UserSubscriberContext";
import Link from "@mui/joy/Link";
import DeleteIcon from "@mui/icons-material/Delete";


class TeamDelete extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            destroyPermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveDestroyPermission();
    }

    async retrieveDestroyPermission() {
        let p = await retrievePermission(
            'OppTeamViewSet',
            'destroy',
            this.context.subscriberObject
        )
        this.setState(
            {
                destroyPermission: p,
            }
        )
    }


    async destroy() {
        const url = `opp_team/${this.props.componentObj.id}/`;
        const api = new BaseApi();
        await api.delete(url, {});
        await this.props.callBack();
    }

    render() {
        return(
            <>
                {this.state.destroyPermission > 0?
                    <Link
                        level="body-xs"
                        color={'danger'}
                        onClick={this.destroy.bind(this)}
                    >
                        <DeleteIcon sx={{mr: 1}}/>
                        Удалить
                    </Link>
                    :null
                }
            </>
        )
    }
}

export default TeamDelete