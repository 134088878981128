import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";


class LeadOutgoingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leads: null,
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveLeads();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveLeads();
        }
    }

    async retrieveLeads() {
        const url = 'lead/';
        const api = new BaseApi();
        let params = {
            xtype: 'outgoing'
        }
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                leads: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.leads) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Исходящие лиды'}
                    url={'lead/outgoing'}
                    data={this.state.leads}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    fields={[
                        {
                            title: 'Тема',
                            name: 'title',
                            visible: true,
                            linkPattern: '/lead/detail/:linkParam',
                            linkParam: 'id'
                        },
                        {
                            title: 'Компания',
                            name: 'account_title',
                            visible: true,
                        },
                        {
                            title: 'Статус',
                            name: 'status_human',
                            chip: true,
                            color_rule: (value, comparison) => {
                                switch (value){
                                    case 'OPEN':
                                        return 'warning'
                                    case 'CLOSE':
                                        return 'success'
                                    case 'LOST':
                                        return 'danger'
                                    default:
                                        return 'primary'
                                }
                            },
                            color_value: 'status',
                            visible: true,
                        },
                        {
                            title: 'Создано',
                            name: 'created_on',
                            visible: true,
                        },
                        {
                            title: 'Обновлено',
                            name: 'updated_on',
                            visible: true,
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(LeadOutgoingList)