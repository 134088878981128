// components
import CommonForm from "../../Shared/CommonForm";
import {splitToList} from "../../../utils/misc";
import BaseApi from "../../../utils/BaseApi";
import CommonActionModal from "../../Shared/CommonActionModal";

// icons
import EditIcon from '@mui/icons-material/Edit';
import TableViewIcon from "@mui/icons-material/TableView";


class DeliveryActionCreateUpdate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: EditIcon,
            buttonLabel: '',
            buttonColor: 'default',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Редактирование порождения',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `delivery_stage_action_create/update_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        if ('next_stages' in formDataObj) {
            formDataObj.next_stages = splitToList(formDataObj.next_stages, ',')
        }
        const url = `delivery_stage_action_create/${this.props.obj.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        if (response.status === 200) {
            await this.setModalOpenFalse();
            await this.props.callBack();
        }
    }

}

export default DeliveryActionCreateUpdate
