import React from "react";
import Typography from "@mui/joy/Typography";
import Pagination from "@mui/material/Pagination";
import {Stack, Table} from "@mui/joy";
import Link from "@mui/joy/Link";
import Chip from "@mui/joy/Chip";

// components
import withNavigate from "../../utils/withNavigate";
import CommonFilterModal from "./CommonFilterModal";
import {generatePath} from "react-router-dom";
import Box from "@mui/joy/Box";
import {getNestedField} from "../../utils/misc";


class CommonPaginatedTable extends React.Component {
    render() {
        return (
            <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        mb: 2
                    }}
                >
                    {
                        this.props.title?
                            <Typography
                                level="h4"
                            >
                                {this.props.title}
                            </Typography>:
                            null
                    }
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        {
                            this.props.createComponent?
                                <this.props.createComponent
                                    {...this.props.createComponentProps}
                                    callBack={this.props.callBack}
                                />:
                                null
                        }
                        {
                            this.props.filterFields?
                                <CommonFilterModal
                                    callBack={this.props.filterCallBack}
                                    filterFields={this.props.filterFields}
                                />:
                                null
                        }
                    </Stack>
                </Stack>
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                >
                    <thead>
                        <tr>
                            {this.props.fields.map((field) => {
                                if (!field.visible){
                                            return null
                                        }
                                return (
                                    <th key={'header-'+field.name} width={field.width?field.width:null}>
                                        {field.title}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((each) => {
                            return (
                                <tr key={this.props.url+'-'+each.id} >
                                    {this.props.fields.map((field) => {
                                        if (!field.visible){
                                            return null
                                        }
                                        return (
                                            <td key={this.props.url+'-'+each.id+'-'+field.name} width={field.width?field.width:null}>
                                                {
                                                    field.linkPattern?
                                                        <Box
                                                            sx={{
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <Link
                                                                href={
                                                                    generatePath(
                                                                        field.linkPattern,
                                                                        {
                                                                            linkParam: getNestedField(each, field.linkParam)
                                                                        }
                                                                    )
                                                                }
                                                                target={field.linkTarget}
                                                            >
                                                                {getNestedField(each, field.name, null, field.preProcess)}
                                                            </Link>
                                                        </Box>
                                                    : field.use_component ?
                                                        <field.component
                                                            componentId={each.id}
                                                            componentObj={each}
                                                            callBack={field.callBack}
                                                            url={this.props.url}
                                                        />
                                                    :
                                                        <>
                                                            {
                                                                field.chip?
                                                                    <Chip
                                                                        sx={{
                                                                            width: '100%',
                                                                        }}
                                                                        color={
                                                                            field.color_rule(
                                                                                field.color_value?getNestedField(each, field.color_value):null,
                                                                                field.color_comparison?getNestedField(each, field.color_comparison):null,
                                                                            )
                                                                        }
                                                                    >
                                                                        {getNestedField(each, field.name, null, field.preProcess)}
                                                                    </Chip>:
                                                                    <>{getNestedField(each, field.name, field.boolean_negative, field.preProcess)}</>
                                                            }
                                                        </>
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {
                    this.props.pages?
                       <Pagination
                            count={this.props.pages}
                            page={this.props.pageNumber}
                            onChange={
                                (event, value) => {
                                    if (typeof this.props.pagesCallback == 'function') {
                                        this.props.pagesCallback(value);
                                    }
                                    if (this.props.url) {
                                        this.props.navigate(`/${this.props.url}/page/`+value);
                                    }
                                }
                            }
                        />:
                        null
                }
            </>
        )
    }
}

export default withNavigate(CommonPaginatedTable)