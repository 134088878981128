import React from 'react';

import {CircularProgress, Step, StepButton, StepIndicator, Stepper} from "@mui/joy";
import {Check} from "@mui/icons-material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

// components
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Shared/CommonForm";
import AttachmentCreate from "../Attachment/AttachmentCreate";
import {arrayUniqueByKey} from "../../utils/misc";


class DeliveryStateCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            steps: [
                {
                    order: 0,
                    title: 'Следующий шаг',
                },
                {
                    order: 1,
                    title: 'Исполнитель',
                },
                {
                    order: 2,
                    title: 'Данные для шага',
                },

            ],
            conditions: null,
            total: null,
            stage: null,
            stages: this.props.deliveryState.stage.next_stages,
            operator: null,
            operators: null,
            attachments: null,
        };
    }

    setActiveStep(index) {
        this.setState(
            {activeStep: index}
        );
    }

    async componentDidMount() {
        await this.retrieveConditions();
        await this.retrieveAttachments();
        if (this.state.stages.length === 0){
            this.setState(
                {
                    activeStep: 2
                }
            )
        }
    }

    async retrieveOperators(role) {
        const url = `opp_team/`;
        const api = new BaseApi();
        const params = {
            opportunity: this.props.opportunityId,
            role: role,
        };
        let response = await api.get(
            url,
            params
        );
        let uniqueSubscribers = arrayUniqueByKey(
            response.data.results, 'subscriber.id'
        )
        return (
           uniqueSubscribers.map(each => {
                return(
                    {
                        value: each.subscriber.id,
                        label: `${each.subscriber.user.first_name} ${each.subscriber.user.last_name}`
                    }
                )
            })
        )
    }

    async retrieveStage(id) {
        const url = `delivery_stage/${id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        return (
            response.data
        )
    }

    async retrieveConditions() {
        const url = `delivery_state/${this.props.deliveryState.id}/conditions/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                conditions: response.data.conditions,
                total: response.data.total
            }
        );
    }

    async retrieveAttachments(){
        this.setState(
            {
                attachments: null
            }
        )
        const url = `attachment/`;
        const api = new BaseApi();
        const params = {
            deleted_bool: false,
            related_app: 'Opportunity',
            related_app_id: this.props.opportunityId,
            xtype: this.props.xtype,
        }
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                attachments: response.data.results,
            }
        );
    }

    async processStep0(form, event) {
        let stage = form.state.formData.get('stage');
        let stage_obj = await this.retrieveStage(stage);
        let operators = await this.retrieveOperators(stage_obj.operator);
        this.setState(
            {
                activeStep: 1,
                stage: stage,
                operators: operators,
            }
        );
    }

    async processStep1(form, event) {
        let operator = form.state.formData.get('operator');
        this.setState(
            {
                activeStep: 2,
                operator: operator,
            }
        );
    }

    async processStep2(form, event) {
        let formData = form.state.formData;
        if (this.state.operator){
            formData.append('operator', this.state.operator);
        }
        if (this.state.stage){
            formData.append('stage', this.state.stage);
        }
        formData.append('delivery', this.props.deliveryState.delivery);
        const url = `delivery_state/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formData,
        );
        if (response.status === 201) {
            await this.props.callBack();
        }
    }

    render() {
        let stepper = (
            <Stepper sx={{width: '100%'}}>
                {this.state.steps.map((step, index) => (
                    <Step
                        key={step}
                        onClick={() => {if(this.state.activeStep >= index){this.setActiveStep({index})}}}
                        indicator={
                            <StepIndicator
                                variant={'solid'}
                                color={this.state.activeStep < index ? 'neutral' : 'success'}
                            >
                                {this.state.activeStep <= index ? index + 1 : <Check/>}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                                ...(this.state.activeStep > index &&
                                    index !== 2 && {bgcolor: 'primary.solidBg'}),
                            },
                        }}
                    >
                        <StepButton>{step.title}</StepButton>
                    </Step>

                ))}
            </Stepper>
        );
        if (this.state.conditions === null){
            return (<CircularProgress/>)
        }
        if (this.state.attachments === null){
            return (<CircularProgress/>)
        }
        if (!this.state.total){
            return (
                <Card
                    color={'warning'}
                    variant="soft"
                >
                    <CardContent>
                        <Typography
                            level={'body-md'}
                        >
                            Требуется выполнить все условия стадии
                        </Typography>
                    </CardContent>
                </Card>
            )
        }
        if (this.state.activeStep === 0){
            return(
                <Card
                    color={'success'}
                    variant={'soft'}
                >
                    {stepper}
                    <CommonForm
                        key={'step-0-form'}
                        fields={[
                            {
                                name: 'stage',
                                label: 'Стадия',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: null,
                                type: 'radio',
                                options: this.state.stages.map((each) => {return(
                                    {'value': each.id, 'label': each.title}
                                )})
                            },
                            {
                                type: 'submit',
                                label: 'Далее',
                                color: 'success',
                            }
                        ]}
                        object={null}
                        processForm={this.processStep0.bind(this)}
                    />
                </Card>
            )
        }
        else if (this.state.activeStep === 1) {
            return(
                <Card
                    color={'success'}
                    variant={'soft'}
                >
                    {stepper}
                    <CommonForm
                        key={'step-1-form'}
                        fields={[
                            {
                                name: 'operator',
                                label: 'Исполнитель',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: null,
                                type: 'radio',
                                options: this.state.operators
                            },
                            {
                                type: 'submit',
                                label: 'Далее',
                                color: 'success',
                            }
                        ]}
                        object={null}
                        processForm={this.processStep1.bind(this)}
                    />
                </Card>
            )
        }
        else if (this.state.activeStep === 2) {
            return(
                <Card
                    color={'success'}
                    variant={'soft'}
                >
                    {stepper}
                    <AttachmentCreate
                        appId={this.props.opportunityId}
                        appName={'Opportunity'}
                        xtype={this.props.xtype}
                        callBack={this.retrieveAttachments.bind(this)}
                    />
                    <CommonForm
                        key={'step-2-form'}
                        fields={[
                            {
                                name: 'attachment',
                                label: 'Вложение',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: this.state.stage?this.state.stage.is_attach_needed:false,
                                default: null,
                                type: 'select',
                                options: this.state.attachments.map((each) => {return(
                                    {'value': each.id, 'label': each.uploadname}
                                )})
                            },
                            {
                                name: 'note',
                                label: 'Комментарий',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: null,
                                type: 'textarea',
                            },
                            {
                                type: 'submit',
                                label: 'Продвинуть',
                                color: 'success',
                            }
                        ]}
                        object={null}
                        processForm={this.processStep2.bind(this)}
                    />
                </Card>
            )
        }
        return (
            <></>
        )
    }
}

export default DeliveryStateCreate