import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";

// components
import EstimationRevisionCard from "./EstimationRevisionCard";
import EstimationRevisionCreate from "./EstimationRevisionCreate";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import retrievePermission from "../../utils/retrievePermission";
import BaseApi from "../../utils/BaseApi";


class EstimationTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            revisions: null,
            createPermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveRevisions();
        await this.retrieveCreatePermission();
    }

    async retrieveCreatePermission(){
        let p = await retrievePermission(
            'EstimationRevisionViewSet',
            'create',
            this.context.subscriberObject
        );
        this.setState(
            {
                createPermission: p
            }
        );
    }

    async retrieveRevisions() {
        this.setState(
            {
                revisions: null
            }
        );
        const url = `estimation_revision/`;
        const api = new BaseApi();
        const params = {
            opportunity: this.props.opportunity.id
        };
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                revisions: response.data.results
            }
        );
    }

    render() {
        if (!this.state.revisions) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                {this.state.revisions.map(each => {
                    return (
                        <EstimationRevisionCard
                            revision={each}
                            callBack={this.retrieveRevisions.bind(this)}
                        />
                    )
                })}
                {
                    this.state.createPermission > 0?
                        <EstimationRevisionCreate
                            opportunity={this.props.opportunity}
                            callBack={this.retrieveRevisions.bind(this)}
                        />:
                        null
                }
            </Stack>
        )
    }
}

export default EstimationTab