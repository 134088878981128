import React from "react";
import BaseApi from "../../../utils/BaseApi";
import {CircularProgress, Stack, } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";



class ContractTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contract: null,
        };
    }

    async componentDidMount() {
        await this.retrieveContract();
    }

    async retrieveContract() {
        const url = `contract/${this.props.opportunity.id}/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        if (response.status === 200){
            this.setState(
                {
                    contract: response.data
                }
            );
        }
    }

    render() {
        if (!this.state.contract) {
            return (<CircularProgress/>)
        }
        return (
            <Stack>
                <Typography>
                    <strong>Номер:</strong> {this.state.contract.contract_number}
                </Typography>
                <Typography>
                    <strong>Тема:</strong> {this.state.contract.contract_theme}
                </Typography>
                <Typography>
                    <strong>Дата:</strong> {dayjs(this.state.contract.contract_date).format('DD.MM.YYYY')}
                </Typography>
                <Typography>
                    <strong>Форма отчетности:</strong> {this.state.contract.contract_report_form.map((each) => {
                        return(<>{each.title};</>)
                    })}
                </Typography>
                <Typography>
                    <strong>Порядок сдачи работ:</strong> <span style={{
                        whiteSpace: 'pre-wrap',
                    }}>{this.state.contract.contract_delivery_order}
                    </span>
                </Typography>
                <Typography>
                    <strong>Количество печатных экз. отчета:</strong> {this.state.contract.contract_print_number}
                </Typography>
                <Typography>
                    <strong>Форма передачи электронных экземпляров:</strong> {this.state.contract.contract_elec_report_form.map((each) => {
                        return(<>{each.title};</>)
                    })}
                </Typography>
            </Stack>
        )
    }
}

export default ContractTab