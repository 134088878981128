import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";

// fullCalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import TaskDetail from "./TaskDetail";


class TasksCalendarTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: null
        };
    }

    async componentDidMount() {
        await this.retrieveTasks();
    }

    async retrieveTasks() {
        const url = 'task/';
        const api = new BaseApi();
        let params = {}
        if ('opportunity' in this.props){
            params.opportunity = this.props.opportunity.id
        }
        if ('accountId' in this.props){
            params.account = this.props.accountId
        }
        let response = await api.get(
            url,
            params
        );
        let events = []
        for (const task of response.data.results) {
            events.push(
                {
                    title: task.title,
                    start: task.start_dt,
                    end: `${task.end_dt}T23:59:59`,
                    obj: task,
                }
            )
        }
        this.setState({
            events: events
        })
    }

    renderEventContent(eventInfo) {
        return (
            <>
                {eventInfo.event.title}
                <TaskDetail
                    taskId={eventInfo.event.extendedProps.obj.id}
                />
            </>
        )
    }

    render() {
        if (!this.state.events) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                variant={'soft'}
            >
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    weekends={false}
                    events={this.state.events}
                    eventContent={this.renderEventContent}
                    locale={'ru'}
                />
            </Sheet>
        )
    }
}

export default TasksCalendarTab;
