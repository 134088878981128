import React from 'react';

import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import dayjs from "dayjs";
import {customRange} from "../../utils/misc";


class PaymentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: null,
            filters: {
                year: new Date().getFullYear()
            },
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrievePayments();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrievePayments();
        }
    }

    async filterCallBack(filters){
        await this.setState({
            filters: filters
        });
        await this.retrievePayments();
    }

    async retrievePayments() {
        const url = 'payment/';
        const api = new BaseApi();
        let params = {}
        params = {...params, ...this.state.filters};
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                payments: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.payments) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Платежи'}
                    url={'financial/payment'}
                    data={this.state.payments}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    filterFields={[
                        {
                                name: 'year',
                                label: 'Год',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: this.state.filters.year,
                                type: 'select',
                                options: customRange(2015, new Date().getFullYear() + 2).map(value => (
                                    {value: value, label: value}
                                ))
                            },
                    ]}
                    filterCallBack={this.filterCallBack.bind(this)}
                    fields={[
                        {
                            title: 'Компания',
                            name: 'opportunity.account.nameshort',
                            linkPattern: '/account/detail/:linkParam',
                            linkParam: 'opportunity.account.id',
                            visible: true,
                        },
                        {
                            title: 'Сделка',
                            name: 'opportunity.short_name',
                            linkPattern: '/opportunity/detail/:linkParam',
                            linkParam: 'opportunity.id',
                            visible: true,
                        },
                        {
                            title: 'Выручка',
                            name: 'revenue',
                            visible: true,
                        },
                        {
                            title: 'Дата',
                            name: 'date',
                            visible: true,
                            preProcess: (value) => (value?dayjs(value).format('DD.MM.YYYY'):value)
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(PaymentList)
