import React from "react";
import {CircularProgress, Stack,} from "@mui/joy";

// components
import CommonPaginatedTable from "../../Shared/CommonPaginatedTable";
import UserSubscriberContext from "../../../context/UserSubscriberContext";
import BaseApi from "../../../utils/BaseApi";
import dayjs from "dayjs";


class PaymentCertificateTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            payments: null,
            certificates: null,
        };
    }

    async componentDidMount() {
        await this.retrievePayments();
        await this.retrieveCertificates();
    }

    async retrievePayments() {
        const url = 'payment/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            opportunity: this.props.opportunity.id,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                payments: response.data.results,
            }
        );
    }

    async retrieveCertificates() {
        const url = 'certificate/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            opportunity: this.props.opportunity.id,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                certificates: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.certificates || !this.state.payments) {
            return (<CircularProgress/>)
        }
        return (
            <Stack>
                <CommonPaginatedTable
                    title={'Входящие платежи'}
                    data={this.state.payments}
                    url={'payment'}
                    fields={[
                        {
                            title: 'Дата',
                            name: 'date',
                            visible: true,
                            preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                        },
                        {
                            title: 'Сумма',
                            name: 'revenue',
                            visible: true,
                        },
                    ]}
                />
                <CommonPaginatedTable
                        title={'Акты'}
                        data={this.state.certificates}
                        url={'certificate'}
                        fields={[
                            {
                                title: 'Дата',
                                name: 'date',
                                visible: true,
                                preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                            },
                            {
                                title: 'Сумма',
                                name: 'revenue',
                                visible: true,
                            },
                        ]}
                    />
            </Stack>
        )
    }
}

export default PaymentCertificateTab
