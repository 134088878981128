// components
import CommonActionModal from "../Shared/CommonActionModal";
import TaskCreate from "./TaskCreate";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentIcon from "@mui/icons-material/Assignment";


class TaskCreateModal extends CommonActionModal {
    componentDidMount(){
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            modalLabel: 'Создание задачи',
            modalIcon: AssignmentIcon,
            mainComponent: TaskCreate,
            mainComponentProps: {
                lockedSteps: this.props.lockedSteps,
                account: this.props.account,
                accountObj: this.props.accountObj,
                opportunity: this.props.opportunity,
                opportunityObj: this.props.opportunityObj,
                phase: this.props.phase,
                phaseObj: this.props.phaseObj,
                activeStep: this.props.activeStep,
                callBack: this.setModalOpenFalse.bind(this),
            },
            ready: true,
        })
    }
}

export default TaskCreateModal
