// components
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";
import CommonForm from "../Shared/CommonForm";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';


class CommunicationCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление способа связи',
            modalIcon: ContactPhoneIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `communication/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return  response.data
    }


    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.related_app = this.props.related_app
        formDataObj.related_app_id = this.props.related_app_id
        const url = `communication/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.props.callBack();
            await this.setModalOpenFalse();
        }
    }
}

export default CommunicationCreate
