// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import EditIcon from '@mui/icons-material/Edit';
import TableViewIcon from "@mui/icons-material/TableView";


class CalculatorItemUpdate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: EditIcon,
            buttonLabel: null,
            buttonColor: 'default',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.constructFormFields()
        return {
            modalLabel: 'Редактирование отчета',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
                object: this.props.item,
            },
        }
    }

    async constructFormFields(){
        let url = `calculator/models/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        let models = response.data
        return [
            {
                'name': 'title',
                'label': 'Заголовок',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'model',
                'label': 'Модель',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': null,
                'type': 'select',
                'options': models
            },
            {
                'name': 'date_field',
                'label': 'Поле даты (выбор из списка)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': null,
                'xl': null,
                'required': false,
                'default': null,
                'type': 'select',
                'options': []
            },
            {
                'name': 'custom_date_field',
                'label': 'Поле даты (свой вариант)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': null,
                'xl': null,
                'required': false,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'field',
                'label': 'Поле (выбор из списка)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': null,
                'xl': null,
                'required': false,
                'default': null,
                'type': 'select',
                'options': []
            },
            {
                'name': 'custom_field',
                'label': 'Поле (свой вариант)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': null,
                'xl': null,
                'required': false,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'period',
                'label': 'Период агрегации',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 'month',
                'type': 'select',
                'options': [
                    {
                        value: 'quarter',
                        label: 'Квартал'
                    },
                    {
                        value: 'month',
                        label: 'Месяц'
                    },
                    {
                        value: 'week',
                        label: 'Неделя'
                    },
                ]
            },
            {
                'name': 'groups',
                'label': 'Группировки',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': false,
                'default': '[]',
                'type': 'stringList',
            },
            {
                'name': 'queryset_params',
                'label': 'Настройки отбора',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': false,
                'default': [],
                'type': 'keyValuePairs',
            },
            {
                type: 'submit',
                label: 'Обновить'
            }
        ];
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        data.id = this.props.item.id;
        await this.props.callBack(data);
        this.setModalOpenFalse();
    }
}

export default CalculatorItemUpdate
