// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import FastForwardIcon from '@mui/icons-material/FastForward';


class TaskDetailDelegate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: FastForwardIcon,
            buttonLabel: 'Делегирование',
            buttonColor: 'success',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let subs = await this.retrieveSubordinates()
        return {
            modalLabel: 'Делегирование',
            modalIcon: FastForwardIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: [
                    {
                        'name': 'new_executor',
                        'label': 'Новый исполнитель',
                        'xs': 12,
                        'sm': null,
                        'md': null,
                        'lg': null,
                        'xl': null,
                        'required': true,
                        'default': subs[0].id,
                        'type': 'select',
                        'options': subs.map((each) => {
                            return (
                                {
                                    'value': each.id,
                                    'label': `${each.user?.first_name} ${each.user?.last_name}`
                                }
                            )
                        })
                    },
                    {
                        type: 'submit',
                        label: 'Сохранить'
                    }
                ],
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveSubordinates() {
        const url = 'subscriber/descendants/';
        const api = new BaseApi();
        let response = await api.get(
            url,{}
        );
        return response.data.results
    }

    async processForm(form){
        const url = `task/${this.props.taskId}/delegate/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            form.state.formData
        );
        if (response.status === 200){
            this.setModalOpenFalse();
            await this.props.callBack();
        }
    }
}

export default TaskDetailDelegate