// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import EditIcon from '@mui/icons-material/Edit';
import TableViewIcon from "@mui/icons-material/TableView";


class EstimationRevisionUpdate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: EditIcon,
            buttonLabel: 'Редактировать',
            buttonColor: 'primary',
            buttonVariant: 'plain',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Редактирование ревизии',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
                object: this.props.item,
            },
        }
    }

    async retrieveFields(){
        const url = `estimation_revision/update_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }

    async processForm(form) {
        const url = `estimation_revision/${this.props.revision.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200) {
            await this.setModalOpenFalse();
            await this.props.callBack();
        }
    }
}

export default EstimationRevisionUpdate
