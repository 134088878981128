import {
  extendTheme as materialExtendTheme,
} from '@mui/material/styles';
import {
    extendTheme as extendJoyTheme,
} from "@mui/joy/styles";
import {indigo, lime, orange, purple, teal, cyan, grey, pink, deepPurple, lightBlue, lightGreen} from "@mui/material/colors";


function createLightColorConfig(name){
    return(
        {
            solidBg: `var(--joy-palette-${name}-400)`,
            solidActiveBg: `var(--joy-palette-${name}-500)`,
            outlinedBorder: `var(--joy-palette-${name}-700)`,
            outlinedColor: `var(--joy-palette-${name}-600)`,
            outlinedActiveBg: `var(--joy-palette-${name}-900)`,
            softColor: `var(--joy-palette-${name}-100)`,
            softBg: `var(--joy-palette-${name}-900)`,
            softActiveBg: `var(--joy-palette-${name}-800)`,
            plainColor: `var(--joy-palette-${name}-100)`,
            plainActiveBg: `var(--joy-palette-${name}-900)`
        }
    )
}

function createDarkColorConfig(name){
    return(
        {
            solidBg: `var(--joy-palette-${name}-400)`,
            solidActiveBg: `var(--joy-palette-${name}-500)`,
            outlinedBorder: `var(--joy-palette-${name}-700)`,
            outlinedColor: `var(--joy-palette-${name}-600)`,
            outlinedActiveBg: `var(--joy-palette-${name}-900)`,
            softColor: `var(--joy-palette-${name}-100)`,
            softBg: `var(--joy-palette-${name}-900)`,
            softActiveBg: `var(--joy-palette-${name}-800)`,
            plainColor: `var(--joy-palette-${name}-100)`,
            plainActiveBg: `var(--joy-palette-${name}-900)`
        }
    )
}


export const materialTheme = materialExtendTheme(
    {
        colorSchemes: {
            dark: true,
        },
    }
);
export const joyTheme = extendJoyTheme(
    {
        typography: {
            'body-sm': {
                fontSize: "0.8rem"
            },
            'body-md': {
                fontSize: "0.9rem"
            },
            'title-md': {
                fontSize: "0.9rem"
            },
            'title-sm': {
                fontSize: "0.8rem"
            },
            'title-lg': {
                fontSize: "1.2rem"
            }
        },
        fontFamily: {
            display: 'Roboto',
            body: 'Roboto',
        },
        colorSchemes: {
            light: {
                palette: {
                    info: Object.assign(cyan, createLightColorConfig('info')),
                    lime: Object.assign(lime, createLightColorConfig('lime')),
                    purple: Object.assign(purple, createLightColorConfig('purple')),
                    teal: Object.assign(teal, createLightColorConfig('teal')),
                    orange: Object.assign(orange, createLightColorConfig('orange')),
                    indigo: Object.assign(indigo, createLightColorConfig('indigo')),
                    grey: Object.assign(grey, createLightColorConfig('grey')),
                    gray: Object.assign(grey, createDarkColorConfig('gray')),
                    pink: Object.assign(pink, createLightColorConfig('pink')),
                    dark: Object.assign(deepPurple, createLightColorConfig('dark')),
                    olive: Object.assign(lightGreen, createLightColorConfig('olive')),
                    'light-blue': Object.assign(lightBlue, createLightColorConfig('light-blue')),
                }
            },
            dark: {
                palette: {
                    info: Object.assign(cyan, createDarkColorConfig('info')),
                    lime: Object.assign(lime, createDarkColorConfig('lime')),
                    purple: Object.assign(purple, createDarkColorConfig('purple')),
                    teal: Object.assign(teal, createDarkColorConfig('teal')),
                    orange: Object.assign(orange, createDarkColorConfig('orange')),
                    indigo: Object.assign(indigo, createDarkColorConfig('indigo')),
                    grey: Object.assign(grey, createDarkColorConfig('grey')),
                    gray: Object.assign(grey, createDarkColorConfig('gray')),
                    pink: Object.assign(pink, createLightColorConfig('pink')),
                    dark: Object.assign(deepPurple, createLightColorConfig('dark')),
                    olive: Object.assign(lightGreen, createLightColorConfig('olive')),
                    'light-blue': Object.assign(lightBlue, createLightColorConfig('light-blue')),
                }
            }
        }
    }
);

