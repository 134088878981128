// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


class IdeaSetOwner extends CommonActionModal{
    async componentDidMount() {
        this.setState({
            buttonIcon: ManageAccountsIcon,
            buttonLabel: 'Назначение ответственного',
            buttonColor: 'warning',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Назначение ответственного',
            modalIcon: ManageAccountsIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        let subscribers = await this.retrieveSubscribers()
        return [
            {
                name: 'owner',
                label: 'Ответственный',
                xs: 12,
                sm: null,
                md: null,
                lg: null,
                xl: null,
                required: true,
                default: null,
                type: 'select',
                options: subscribers.map(
                    (each) => (
                        {
                            value: each.id,
                            label: `${each.user.first_name} ${each.user.last_name}`
                        }
                    )
                )
            },
            {
                type: 'submit',
                label: 'Применить',
            },
        ]
    }

    async retrieveSubscribers() {
        const url = 'subscriber/';
        const params = {
            active: true,

        }
        const api = new BaseApi();
        let response = await api.get(
            url,
            params,
        );
        return response.data.results
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        const url = `idea_node/${this.props.ideaNode.id}/`;
        const api = new BaseApi();
        await api.patch(
            url,
            formDataObj
        );
        await this.setModalOpenFalse();
    }

    async setModalOpenFalse() {
        if (typeof this.props.callBack == 'function') {
                await this.props.callBack();
            }
        this.setState(
            {
                modalOpen: false,
            }
        );
    }
}

export default IdeaSetOwner
