import React from "react";

import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import retrievePermission from "../../utils/retrievePermission";

// Tabs
import OpportunityDetailHeader from "./OpportunityDetailHeader";
import DeliveryTab from "../Delivery/DeliveryTab";
import TeamTab from "./Tabs/TeamTab";
import ContactTeamTab from "./Tabs/ContactTeamTab";
import AttachmentsTab from "../Attachment/AttachmentsTab";
import TasksTab from "../Task/TasksTab";
import TasksCalendarTab from "../Task/TasksCalendarTab"
import EstimationTab from "../Estimation/EstimationTab";
import QualificatorTab from "../Qualificator/QualificatorTab";
import EvaluationTab from "./Tabs/EvaluationTab"
import ContractTab from "./Tabs/ContractTab";
import PaymentCertificateTab from "./Tabs/PaymentCertificateTab";

// icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScaleIcon from '@mui/icons-material/Scale';
import GradeIcon from '@mui/icons-material/Grade';
import GavelIcon from '@mui/icons-material/Gavel';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import LaunchIcon from "@mui/icons-material/Launch";
import Link from "@mui/joy/Link";
import CommonEditModal from "../Shared/CommonEditModal";


class OpportunityDetail extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            opportunity: null,
            updatePermission: null,
            paymentPermission: null,
            certificatePermission: null,
        };
    }

    async componentDidMount() {
        await this.retrieveUpdatePermissions();
        await this.retrieveOpportunity();
    }

    async retrieveUpdatePermissions() {
        let paymentPermission = await retrievePermission(
            'OppPaymentViewSet',
            'list',
            this.context.subscriberObject
        );
        let certificatePermission = await retrievePermission(
            'CertificateViewSet',
            'list',
            this.context.subscriberObject
        );
        let updatePermission = await retrievePermission(
            'OpportunityViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        this.setState(
            {
                paymentPermission: paymentPermission,
                certificatePermission: certificatePermission,
                updatePermission: updatePermission,
            }
        );
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveOpportunity();
        }
    }

    async retrieveOpportunity() {
        this.setState(
            {
                opportunity: null
            }
        );
        const url = `opportunity/${this.props.params.opportunityId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                opportunity: response.data
            }
        );
    }

    render() {
        if (!this.state.opportunity) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'Opportunity'}
                appId={this.state.opportunity.id}
                header={{
                    component: OpportunityDetailHeader,
                    componentProps: {
                        opportunity: this.state.opportunity
                    },
                    leftOverflow: {
                        icon: null,
                        title: this.state.opportunity.status_human,
                        color: this.state.opportunity.status_color,
                        component: <CommonEditModal
                            fields={
                                [
                                    'status',
                                ]
                            }
                            baseUrl={'opportunity'}
                            obj={this.state.opportunity}
                            buttonIconOnly={true}
                            callBack={this.retrieveOpportunity.bind(this)}
                            permission={this.state.updatePermission}
                            deniedPermissionComponent={<></>}
                        />,
                    },
                    rightOverflow: {
                        icon: <VideoChatIcon />,
                        title: 'ВКС',
                        color: 'primary',
                        component: <Link
                            level={'body-md'}
                            href={`https://meet.multiphysics.ru/${this.state.opportunity.uuid}`}
                            target={'_blank'}
                            color="inherit"
                            underline="none"
                        >
                            <LaunchIcon />
                        </Link>
                    }
                }}
                defaultTabValue={'delivery'}
                tabComponents={[
                    {
                        title: 'Бизнес-процессы',
                        value: 'delivery',
                        icon: AccountTreeIcon,
                        component: <DeliveryTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Команда',
                        value: 'team',
                        icon: GroupsIcon,
                        component: <TeamTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Команда заказчика',
                        value: 'contact-team',
                        icon: PeopleOutlineIcon,
                        component: <ContactTeamTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Вложения',
                        value: 'attachment',
                        icon: AttachFileIcon,
                        component: <AttachmentsTab
                            appId={this.state.opportunity.id}
                            appName={'Opportunity'}
                        />
                    },
                    {
                        title: 'Квалификатор',
                        value: 'qualificator',
                        icon: ScaleIcon,
                        component: <QualificatorTab
                            qualificatorId={this.state.opportunity.qualificator}
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Трудоемкость',
                        value: 'estimation',
                        icon: LocalPizzaIcon,
                        component: <EstimationTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Задачи',
                        value: 'task',
                        icon: AssignmentIcon,
                        component: <TasksTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Календарь',
                        value: 'calendar',
                        icon: CalendarMonthIcon,
                        component: <TasksCalendarTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Оценка проекта',
                        value: 'evaluation',
                        icon: GradeIcon,
                        component: <EvaluationTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Договор',
                        value: 'contract',
                        icon: GavelIcon,
                        component: <ContractTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Платежи и Акты',
                        value: 'paymentCertificate',
                        icon: GavelIcon,
                        component: <PaymentCertificateTab
                            opportunity={this.state.opportunity}
                        />,
                        hidden: this.state.certificatePermission && this.state.paymentPermission
                    },
                ]}
            />
        )
    }
}

export default withRouter(OpportunityDetail);
