import React from "react";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";


class CommonActionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            ready: false
        };
    }

    async setModalOpenTrue(){
        let addState = {};
        if (this.beforeModalOpen) {
            addState = await this.beforeModalOpen();
        }
        addState.modalOpen = true
        this.setState(addState);
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        if (!this.state.ready) {
            return (
                <CircularProgress/>
            )
        }
        if (this.state.permission !== undefined && !this.state.permission){
            return(
                <>
                    {
                        this.state.deniedPermissionComponent?
                            this.state.deniedPermissionComponent:
                            null
                    }
                </>
            )
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                {
                    this.state.buttonLabel?
                        <Button
                            size={'sm'}
                            color={this.state.buttonColor}
                            sx={{mt: 1, mb: 1, mr: 1}}
                            onClick={this.setModalOpenTrue.bind(this)}
                            startDecorator={this.state.buttonIcon?<this.state.buttonIcon fontSize={'small'}/>:null}
                            variant={this.state.buttonVariant?this.state.buttonVariant:'solid'}
                        >
                            {this.state.buttonLabel}
                        </Button>:
                        this.state.buttonIconOnly?
                            <this.state.buttonIcon
                                fontSize={'small'}
                                sx={{
                                    cursor: 'pointer',
                                }}
                                onClick={this.setModalOpenTrue.bind(this)}
                            />:
                            <IconButton
                                color={this.state.buttonColor}
                                sx={{mt: 1, mb: 1, mr: 1}}
                                onClick={this.setModalOpenTrue.bind(this)}
                                startDecorator={this.state.buttonIcon?<this.state.buttonIcon fontSize={'small'}/>:null}
                            >
                                <this.state.buttonIcon fontSize={'small'}/>
                            </IconButton>
                }
                {
                    this.state.extraButtons ?
                        this.state.extraButtons.map(each => (
                            <Button
                                size={'sm'}
                                color={each.color}
                                sx={{mt: 1, mb: 1, mr: 1}}
                                onClick={each.onClick}
                                startDecorator={each.icon ? <each.icon fontSize={'small'}/> : null}
                            >
                                {each.label}
                            </Button>
                        )):
                        null
                }
                {
                    this.state.modalOpen?
                        <Modal
                            open={this.state.modalOpen}
                        >
                            <ModalDialog
                                layout={'center'}
                                size="lg"
                            >
                                <ModalClose
                                    onClick={this.setModalOpenFalse.bind(this)}
                                />
                                <Box
                                    sx={{
                                        overflowY: 'scroll',
                                        width: this.props.fullWidth?'calc(80vw)':null,
                                        height: this.props.fullHeight?'calc(80vh)':null,
                                        pr: 2
                                    }}
                                >
                                    <Typography
                                        level='h3'
                                        sx={{
                                            'mb': 3,
                                        }}
                                        startDecorator={this.state.modalIcon?<this.state.modalIcon fontSize={'small'}/>:null}
                                    >
                                         {this.state.modalLabel}
                                    </Typography>
                                    <this.state.mainComponent
                                        {...this.state.mainComponentProps}
                                    />
                                </Box>
                            </ModalDialog>
                        </Modal>:
                        null
                }
            </Box>
        )
    }
}

export default CommonActionModal
