import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary, CircularProgress, Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import PermissionViewSet from "./PermissionViewSet";
import Chip from "@mui/joy/Chip";



class PermissionReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
            subscriberTypes: null,
        };
    }

    async componentDidMount() {
        await this.retrieveReport();
        await this.retrieveSubscriberTypes();
    }

    async retrieveSubscriberTypes() {
        this.setState(
            {
                phases: null,
            }
        );
        const url = `subscriber_type/`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        let subscriberTypes = {};
        for (let subscriberType of response.data.results) {
                subscriberTypes[subscriberType.id] = subscriberType.title
        }
        this.setState(
            {
                subscriberTypes: subscriberTypes
            }
        );
    }

    async retrieveReport() {
        this.setState(
            {
                phases: null,
            }
        );
        const url = `api_permission/report`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        this.setState(
            {
                report: response.data
            }
        );
    }

    render(){
        if (!this.state.report){
            return (<CircularProgress />)
        }
        if (!this.state.subscriberTypes){
            return (<CircularProgress />)
        }
        return(
            <>
                <Typography level={'h2'}>
                    Права API
                </Typography>
                <AccordionGroup>
                    {
                        this.state.report.map(each => {
                            return(
                                <Accordion>
                                    <AccordionSummary>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                pb: 1
                                            }}
                                        >
                                            <Typography level={'h4'}>
                                                {each[0][0]}

                                            </Typography>
                                            <Chip
                                                color={'grey'}
                                            >
                                                {each[0][1]}
                                            </Chip>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <PermissionViewSet
                                            viewset={each}
                                            subscriberTypes={this.state.subscriberTypes}
                                        />
                                    </AccordionDetails>

                                </Accordion>
                            )
                        })
                    }
                </AccordionGroup>
            </>
        )
    }
}

export default PermissionReport