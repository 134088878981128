import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";

// components
import withNavigate from "../../utils/withNavigate";
import retrievePermission from "../../utils/retrievePermission";
import CommonEditModal from "../Shared/CommonEditModal";
import BaseApi from "../../utils/BaseApi";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class OpportunityDetailHeader extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            opportunity: this.props.opportunity,
            permission: 0,
        };
    }

    async componentDidMount(){
        await this.retrievePermission();
    }

    async retrieveOpportunity() {
        this.setState(
            {
                opportunity: null
            }
        );
        const url = `opportunity/${this.props.opportunity.id}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                opportunity: response.data
            }
        );
    }

    async retrievePermission() {
        let p = await retrievePermission(
            'OpportunityViewSet',
            'partial_update',
            this.context.subscriberObject
        )
        this.setState(
            {
                permission: p,
            }
        )
    }

    render() {
        if (!this.state.opportunity) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={1}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    useFlexGap
                    sx={{ flexWrap: 'wrap' }}
                >
                    <Typography level={'title-md'}>
                        {this.state.opportunity.short_name}
                    </Typography>
                    <CommonEditModal
                        fields={
                            [
                                'short_name',
                            ]
                        }
                        baseUrl={'opportunity'}
                        obj={this.state.opportunity}
                        callBack={this.retrieveOpportunity.bind(this)}
                        buttonIconOnly={true}
                    />
                    <Chip
                        color={'primary'}
                        sx={{
                            maxWidth: '150px',
                        }}
                    >
                        {this.state.opportunity.source?.title}
                    </Chip>
                    <CommonEditModal
                        fields={
                            [
                                'source',
                            ]
                        }
                        baseUrl={'opportunity'}
                        obj={{source: this.state.opportunity.source?.id}}
                        callBack={this.retrieveOpportunity.bind(this)}
                        buttonIconOnly={true}
                    />
                    <Chip
                        color={'primary'}
                        sx={{
                            maxWidth: '150px',
                        }}
                    >
                        {this.state.opportunity.revenue_type?.title}
                    </Chip>
                    <CommonEditModal
                        fields={
                            [
                                'revenue_type',
                            ]
                        }
                        baseUrl={'opportunity'}
                        obj={{revenue_type: this.state.opportunity.revenue_type?.id}}
                        callBack={this.retrieveOpportunity.bind(this)}
                        buttonIconOnly={true}
                    />
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    useFlexGap
                    sx={{ flexWrap: 'wrap' }}
                >
                    <Typography level={'title-md'}>
                        Полное название: {this.state.opportunity.name}
                    </Typography>
                    <CommonEditModal
                        fields={
                            [
                                'name',
                            ]
                        }
                        baseUrl={'opportunity'}
                        obj={this.state.opportunity}
                        callBack={this.retrieveOpportunity.bind(this)}
                        buttonIconOnly={true}
                    />
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Typography
                        level={'title-md'}
                    >
                        Компания:&nbsp;
                        <Link onClick={() => this.props.navigate(`/account/detail/${this.state.opportunity.account.id}`)}>
                             {this.state.opportunity.account.nameshort}
                        </Link>
                    </Typography>
                </Stack>
            </Stack>
        )
    }
}

export default withNavigate(OpportunityDetailHeader);
