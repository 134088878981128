import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";

// components
import ContactDetailHeader from "./ContactDetailHeader";
import ContactTab from "./ContactTab";


// icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";


class ContactDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: null,
        };
    }

    async componentDidMount() {
        await this.retrieveContact();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveContact();
        }
    }

    async retrieveContact() {
        this.setState(
            {
                contact: null
            }
        );
        const url = `contact/${this.props.params.contactId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                contact: response.data
            }
        );
    }

    render() {
        if (!this.state.contact) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'Contact'}
                appId={this.state.contact.id}
                header={{
                    component: ContactDetailHeader,
                    componentProps: {
                        contact: this.state.contact,
                        callBack: this.retrieveContact.bind(this)
                    },
                    leftOverflow: {
                        icon: null,
                        title: 'Контакт',
                        color: 'primary'
                    },
                }}
                defaultTabValue={'info'}
                tabComponents={[
                    {
                        title: 'Информация',
                        value: 'info',
                        icon: AccountTreeIcon,
                        component: <ContactTab
                            contact={this.state.contact}
                            callBack={this.retrieveContact.bind(this)}
                        />,
                    },
                ]}
            />
        )
    }
}

export default withRouter(ContactDetail);
