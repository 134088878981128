import dayjs from "dayjs";


let quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);


export function calculateTotals(
    data,
    incomeFact,
    incomeForecast,
    incomeLeadTail,
    revenueFact,
    revenueForecast,
    revenueLeadTail,
    expenses,
    salaries,
    tax,
    taxForecast,
    balance,
    balanceForecast,
){
    let total = {
        tax: 0,
        taxForecast: 0,
        incomeFact: 0,
        incomeForecast: 0,
        incomeLeadTail: 0,
        revenueFact: 0,
        revenueForecast: 0,
        revenueLeadTail: 0,
        expense: 0,
        salaries: 0,
        workDays: 0,
        balance: 0,
        balanceForecast: 0,
        profit: 0,
        profitForecast: 0,
    }
    let quarters = {
        1: structuredClone(total),
        2: structuredClone(total),
        3: structuredClone(total),
        4: structuredClone(total),
    };

    for (let day of data){
        let quarter = dayjs(day.date).quarter();
        quarters[quarter].incomeFact += day[incomeFact]?day[incomeFact]:0;
        quarters[quarter].incomeForecast += day[incomeForecast]?day[incomeForecast]:0;
        quarters[quarter].incomeLeadTail += day[incomeLeadTail]?day[incomeLeadTail]:0;
        quarters[quarter].revenueFact += day[revenueFact]?day[revenueFact]:0;
        quarters[quarter].revenueForecast += day[revenueForecast]?day[revenueForecast]:0;
        quarters[quarter].revenueLeadTail += day[revenueLeadTail]?day[revenueLeadTail]:0;
        quarters[quarter].tax += day[tax]?day[tax]:0;
        quarters[quarter].taxForecast += day[taxForecast]?day[taxForecast]:0;
        for (let expense of expenses){
            quarters[quarter].expense += day[expense]?day[expense]:0;
        }
        for (let salary of salaries){
            quarters[quarter].salaries += day[salary]?day[salary]:0;
        }
        quarters[quarter].balance += day[balance]?day[balance]:0;
        quarters[quarter].balanceForecast += day[balanceForecast]?day[balanceForecast]:0;
    }
    for (let [c, q] of Object.entries(quarters)){
        q.profitForecast = q.revenueFact + q.revenueForecast + q.revenueLeadTail - q.expense - q.salaries - q.tax - q.taxForecast;
        q.profit = q.revenueFact - q.expense - q.salaries - q.tax;
        for (let k of Object.keys(q)){
            total[k] += q[k]
        }
    }
    return [total, quarters]
}