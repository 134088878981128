import React from 'react';
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import {CardOverflow, Stack} from "@mui/joy";

// components
import DeliveryStateCardInner from "./DeliveryStateCardInner";


class DeliveryStateCard extends React.Component {
    render() {
        return (
            <Card
                variant={'plain'}
                sx={{
                    mb: 2,
                }}
            >
                <CardOverflow
                    variant={'soft'}
                    color={this.props.state.stage.color}
                >
                    <Typography
                        level={'body-md'}
                        sx={{
                            alignSelf: 'center',
                            p: 1,
                        }}
                    >
                        {this.props.state.stage.title}
                    </Typography>
                </CardOverflow>
                <DeliveryStateCardInner
                    state={this.props.state}
                    conditions={true}
                />
            </Card>
        )
    }
}

export default DeliveryStateCard