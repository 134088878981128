import React from "react";
import BaseApi from "../../../utils/BaseApi";
import {CircularProgress, Table} from "@mui/joy";
import ProfileHorizontalByProps from "../../Profile/ProfileHorizontalByProps";
import Typography from "@mui/joy/Typography";



class EvaluationTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evaluation: null,
        };
    }

    async componentDidMount() {
        await this.retrieveEvaluation();
    }

    async retrieveEvaluation() {
        const url = `opportunity/${this.props.opportunity.id}/evaluation/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                evaluation: response.data
            }
        );
    }

    render() {
        if (!this.state.evaluation) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--td-headBackground': (theme) =>
                        theme.vars.palette.background.level1,
                        '--Table-headerUnderlineThickness': '1px',
                        '--tr-hoverBackground': (theme) =>
                        theme.vars.palette.background.level1,
                    }}
                >
                    <thead>
                        <tr>
                            <th>
                                Сотрудник
                            </th>
                            <th>
                                Загрузка план<br/>(в трудоемости + вне)
                            </th>
                            <th>
                                Загрузка факт<br/>(в трудоемости + вне)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.evaluation.user_workloads.map((row) => (
                            <tr key={row.user.id}>
                                <td>
                                    <ProfileHorizontalByProps
                                        user={row.user}
                                    />
                                </td>
                                <td>
                                    {row.workload_plan_estimation} + {row.workload_plan_not_estimation}
                                </td>
                                <td>
                                    {row.workload_fact_estimation} + {row.workload_fact_not_estimation}
                                </td>
                            </tr>
                        ))}
                        <tr key={'totals'}>
                            <td>
                                <strong>Итого</strong>
                            </td>
                            <td>
                                <strong>{this.state.evaluation.total_workloads.workload_plan_estimation} + {this.state.evaluation.total_workloads.workload_plan_not_estimation}</strong>
                            </td>
                            <td>
                                <strong>{this.state.evaluation.total_workloads.workload_fact_estimation} + {this.state.evaluation.total_workloads.workload_fact_not_estimation}</strong>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Typography
                    sx={{
                        mt: 2
                    }}
                >
                    <strong>
                        Ожидаемая трудоемкость: {this.state.evaluation.total_workloads.max_proba_estimation}
                    </strong>
                </Typography>
            </>
        )
    }
}

export default EvaluationTab