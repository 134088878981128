import React from 'react';
import CommonSidebar from "../Shared/CommonSidebar";

//icons
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactPageIcon from '@mui/icons-material/ContactPage';


class AccountSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'Контрагенты',
                        items: [
                            {
                                title: 'Компании',
                                link: '/account/',
                                icon: ApartmentIcon,
                            },
                            {
                                title: 'Контакты',
                                link: '/contact/',
                                icon: ContactPageIcon,
                            }
                        ]
                    }
                ]}
            />
        )
    }
}

export default AccountSidebar;
