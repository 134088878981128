import React from "react";
import {CircularProgress} from "@mui/joy";

// components
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";
import BaseApi from "../../utils/BaseApi";
import MarketingUserDetailHeader from "./MarketingUserDetailHeader";
import MarketingUserUTMTab from "./MarketingUserUTMTab";
import MarketingUserVisitTab from "./MarketingUserVisitTab";
import MarketingUserWebinarTab from "./MarketingUserWebinarTab";


// icons
import InfoIcon from "@mui/icons-material/Info";
import PeopleIcon from '@mui/icons-material/People';
import MarketingUserTab from "./MarketingUserTab";


class MarketingUserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
        };
    }

    async componentDidMount() {
        await this.retrieveUser();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveUser();
        }
    }

    async retrieveUser() {
        await this.setState(
            {
                webinar: null
            }
        );
        const url = `marketing_user/${this.props.params.userId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                user: response.data
            }
        );
    }

    render() {
        if (!this.state.user) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'MarketingUser'}
                appId={this.state.user.id}
                header={{
                    component: MarketingUserDetailHeader,
                    componentProps: {
                        user: this.state.user
                    },
                    leftOverflow: {
                        icon: null,
                        title: 'Пользователь',
                        color: 'primary'
                    },

                }}
                defaultTabValue={'info'}
                tabComponents={[
                    {
                        title: 'Информация',
                        value: 'info',
                        icon: InfoIcon,
                        component: <MarketingUserTab
                            user={this.state.user}
                            callBack={this.retrieveUser.bind(this)}
                        />,
                    },
                    {
                        title: 'Вебинары',
                        value: 'webinars',
                        icon: PeopleIcon,
                        component: <MarketingUserWebinarTab
                            user={this.state.user}
                            callBack={this.retrieveUser.bind(this)}
                        />,
                    },
                    {
                        title: 'Визиты',
                        value: 'present',
                        icon: PeopleIcon,
                        component: <MarketingUserVisitTab
                            user={this.state.user}
                            callBack={this.retrieveUser.bind(this)}
                        />,
                    },
                    {
                        title: 'Источники',
                        value: 'utm',
                        icon: PeopleIcon,
                        component: <MarketingUserUTMTab
                            user={this.state.user}
                            callBack={this.retrieveUser.bind(this)}
                        />,
                    },
                ]}
            />
        )
    }
}

export default withRouter(MarketingUserDetail);
