import React from 'react';
import dayjs from "dayjs";

import {CircularProgress, Stack,} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import {LineChart} from "@mui/x-charts";
import Box from "@mui/joy/Box";

// components
import BaseApi from "../../utils/BaseApi";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import CommonForm from "../Shared/CommonForm";
import FinancialModelForecastParameters from "./FinancialModelForecastParameters";
import FinancialModelOpportunityCardWrapper from "./FinancialModelOpportunityCardWrapper";
import FinancialModelForecastStartDate from "./FinancialModelForecastStartDate";
import {compareDates, getDatesInRange} from "../../utils/misc";
import FinancialTotals from "./FinancialTotals";
import {calculateTotals} from "./utils";


dayjs.locale('ru');


class FinancialReport extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            opportunities: [],
            forecast_revenues: [],
            forecast_incomes: [],
            payments: null,
            certificates: null,
            statuses: ['OPENX',],
            xtypes_choices: null,
            xtypes: [],
            ready: false,
            report: null,
            total: null,
            quarters: null,
            year: new Date().getFullYear()
        }
    }

    async componentDidMount() {
        await this.retrievePayments();
        await this.retrieveCertificates();
        await this.retrieveXtypes();
        await this.calculateReport();
    }

    async retrieveXtypes() {
        const api = new BaseApi();
        let url = 'attachment/subscriber_xtypes/';
        let response = await api.get(
            url,
            {},
        );
        let xtypes_choices = [];
        for (const category of response.data.all_xtypes) {
            if (response.data.available_xtypes.includes(category[0])) {
                xtypes_choices.push(category);
            }
        }
        this.setState({
            xtypes_choices: xtypes_choices,
        });
    }

    async retrieveOpportunities() {
        const url = 'opportunity/report/';
        const api = new BaseApi();
        let params = {
            xtypes: this.state.xtypes.join(','),
            cards: true,
        };
        let opportunities = [];
        for (let status of this.state.statuses){
            params.status = status;
            let response = await api.get(
                url,
                params
            );
            opportunities = opportunities.concat(response.data.cards)
        }
        this.setState({
            opportunities: opportunities,
        });
    }

    async retrievePayments() {
        const url = 'payment/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            year: this.state.year,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                payments: response.data.results,
            }
        );
    }
    async retrieveCertificates() {
        const url = 'certificate/';
        const api = new BaseApi();
        let params = {
            page_size: 10000,
            year: this.state.year,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                certificates: response.data.results,
            }
        );
    }

    async processOpportunityFilter(form) {
        await this.setState({
            statuses: [],
            xtypes: [],
        })
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        await this.setState({
            statuses: formDataObj.statuses.split(','),
            xtypes: formDataObj.xtypes.split(','),
        });
        await this.retrieveOpportunities();
    }

    async refresh(){
        await this.retrievePayments();
        await this.retrieveCertificates();
        await this.calculateReport();
    }

    async updateOpportunityList(){
        await this.retrieveOpportunities();
        await this.calculateReport();
    }

    async calculateReport() {
        this.setState({
            ready: false,
        })

        let url;
        let params;
        let response;
        const api = new BaseApi();
        url = 'finance/report';
        params = {
            year: this.state.year,
        }
        response = await api.get(url, params);
        let startCash = response.data.startCash;
        let days = response.data.days;
        let report = {};
        let startDate = new Date(this.state.year, 0, 1);
        let endDate = new Date(this.state.year,11, 31);
        let dates = getDatesInRange(startDate, endDate);
        let date_keys = Object.keys(days[0]);
        for (let date of dates) {
            report[date.toLocaleDateString('en-US')] = {};
            for (let k of date_keys) {
                report[date.toLocaleDateString('en-US')][k] = 0;
            }
            report[date.toLocaleDateString('en-US')].date = date.toLocaleDateString('en-US')
            report[date.toLocaleDateString('en-US')].revenueForecast = 0
            report[date.toLocaleDateString('en-US')].paymentsForecast = 0
            report[date.toLocaleDateString('en-US')].payment_taxForecast = 0
        }
        for (let day of days) {
            for (let k of date_keys) {
                if (k !== 'date'){
                    report[dayjs(day.date).toDate().toLocaleDateString('en-US')][k] += day[k]
                }
            }
        }
        url = 'opportunity/forecast';
        params = {
            year: this.state.year,
        }
        response = await api.get(url, params);
        for (let r of response.data.by_start_date.revenue){
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].revenueForecast += r.value;
        }
        for (let r of response.data.by_start_date.income){
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].paymentsForecast += r.value;
            report[dayjs(r.date).toDate().toLocaleDateString('en-US')].payment_taxForecast += r.value * 0.03;
        }
        let reportList = []
        for (let day of Object.entries(report)){
            reportList.push(
                day[1]
            )
        }
        reportList.sort(
            function(a, b) {
                return compareDates(a, b)
            }
        )

        let prevCash = startCash;
        let prevCashForecast = startCash;
        for (let day of reportList){
            day.cashflow = day.payments - day.employee_sum - day.employee_bonus_sum - day.overhead_cash_by_date - day.employee_tax - day.payment_tax;
            day.cashflowForecast = day.cashflow + day.paymentsForecast - day.payment_taxForecast
            day.expenses = day.employee_sum + day.employee_bonus_sum + day.overhead_cash_by_date + day.employee_tax + day.payment_tax;
            day.expensesForecast = day.expenses + day.payment_taxForecast
            day.cash = prevCash + day.cashflow;
            day.cashForecast = prevCashForecast + day.cashflowForecast;
            prevCash = day.cash;
            prevCashForecast = day.cashForecast;
        }
        let [total, quarters] = calculateTotals(
            reportList,
            'payments',
            'paymentsForecast',
            'incomeLeadTail',
            'certificates',
            'revenueForecast',
            'revenueLeadTail',
            ['overhead_periodic', 'overhead_by_date',],
            ['employee_sum', 'employee_bonus_sum', 'employee_tax'],
            'payment_tax_profit',
            'payment_taxForecast',
            'cashflow',
            'cashflowForecast',
        )
        let forecast_revenues = response.data.by_start_date.revenue;
        forecast_revenues.sort(
            function(a, b) {
                return compareDates(a, b)
            }
        )
        let forecast_incomes = response.data.by_start_date.income;
        forecast_incomes.sort(
            function(a, b) {
                return compareDates(a, b)
            }
        )
        this.setState({
            forecast_revenues: forecast_revenues,
            forecast_incomes: forecast_incomes,
            report: reportList,
            total: total,
            quarters: quarters,
            ready: true,
        });
    }

    render() {
        let numberFormat = new Intl.NumberFormat('ru', {maximumFractionDigits: 2})
        if (!this.state.ready) {
            return (
                <CircularProgress/>
            )
        }
        return (
            <Stack
                spacing={2}
                sx={{
                    width: '100%',
                }}
            >
                <Typography
                    level="h3"
                >
                    Финансовый отчет
                </Typography>
                <Card>
                    <CardContent>
                        <FinancialTotals
                            total={this.state.total}
                            quarters={this.state.quarters}
                        />
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <LineChart
                            dataset={this.state.report}
                            series={[
                                {
                                    dataKey: 'cash',
                                    label: 'Остаток',
                                    color: '#4e79a7',
                                },
                                {
                                    dataKey: 'cashForecast',
                                    label: 'Остаток (прогноз)',
                                    color: '#edc949',
                                },
                            ]}
                            grid={
                                {
                                    horizontal: true
                                }
                            }
                            margin={{ left: 100, right: 30, top: 30, bottom: 30 }}
                            height={400}
                        />
                    </CardContent>
                </Card>
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Входящие платежи'}
                                data={this.state.payments}
                                url={'payment'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'revenue',
                                        visible: true,
                                    },
                                ]}
                            />
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Акты'}
                                data={this.state.certificates}
                                url={'certificate'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'revenue',
                                        visible: true,
                                    },
                                ]}
                            />
                        </CardContent>
                    </Card>
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Входящие платежи (прогноз)'}
                                data={this.state.forecast_incomes}
                                url={'payment'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'value',
                                        visible: true,
                                    },

                                ]}
                            />
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            height: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <CardContent
                        >
                            <CommonPaginatedTable
                                title={'Акты (прогноз)'}
                                data={this.state.forecast_revenues}
                                url={'certificate'}
                                fields={[
                                    {
                                        title: 'Дата',
                                        name: 'date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                                    },
                                    {
                                        title: 'Сделка',
                                        name: 'opportunity.short_name',
                                        visible: true,
                                        linkPattern: '/opportunity/detail/:linkParam',
                                        linkParam: 'opportunity.id',
                                        linkTarget: '_blank'
                                    },
                                    {
                                        title: 'Сумма',
                                        name: 'value',
                                        visible: true,
                                    },
                                ]}
                            />
                        </CardContent>
                    </Card>
                </Stack>
                <Typography
                    level={'h4'}
                >
                    Сделки в прогнозе
                </Typography>
                <CommonForm
                    processForm={this.processOpportunityFilter.bind(this)}
                    fields={[
                        {
                            name: 'statuses',
                            label: 'Статусы сделки',
                            xs: 12,
                            sm: null,
                            md: 6,
                            lg: null,
                            xl: null,
                            required: true,
                            default: ['OPENX',],
                            type: 'multiselect',
                            options: [
                                {'value': 'OPENX', 'label': 'В работе'},
                                {'value': 'DELAY', 'label': 'Ожидает'},
                                {'value': 'CLOSE', 'label': 'Закрыта'},
                                {'value': 'LOSTX', 'label': 'Потеряна'},
                            ]
                        },
                        {
                            'name': 'xtypes',
                            'label': 'Типы процессов',
                            'xs': 12,
                            'sm': null,
                            'md': 6,
                            'lg': null,
                            'xl': null,
                            'required': false,
                            'default': [],
                            'type': 'multiselect',
                            'options': this.state.xtypes_choices.map((each) => {return(
                                {
                                    'value': each[0],
                                    'label': each[1]
                                }
                            )})
                        },
                        {
                            'type': 'submit',
                            'label': 'Отобрать'
                        }
                    ]}
                />
                <Box
                    sx={{
                        height: '600px',
                        overflowY: 'scroll',
                    }}
                >
                    <CommonPaginatedTable
                        title={''}
                        data={this.state.opportunities}
                        fields={[
                            {
                                title: 'Название',
                                name: 'opportunity.short_name',
                                visible: true,
                                linkPattern: '/opportunity/detail/:linkParam',
                                linkParam: 'opportunity.id',
                                linkTarget: '_blank'
                            },
                            {
                                title: 'Компания',
                                name: 'opportunity.account.nameshort',
                                visible: true,
                                linkPattern: '/account/detail/:linkParam',
                                linkParam: 'opportunity.account.id',
                                linkTarget: '_blank'
                            },
                            {
                                title: 'Карточка',
                                visible: true,
                                use_component: true,
                                component: FinancialModelOpportunityCardWrapper,
                                // callBack: this.calculateReport.bind(this)
                            },
                            {
                                title: 'Текущее ТКП - цена',
                                name: 'opportunity.current_quote.price',
                                visible: true,
                            },
                            {
                                title: 'Текущее ТКП - рабочие дни',
                                name: 'opportunity.current_quote.work_days',
                                visible: true,
                            },
                            {
                                title: 'Параметры в прогнозе',
                                visible: true,
                                use_component: true,
                                component: FinancialModelForecastParameters,
                                callBack: this.updateOpportunityList.bind(this),
                                width: '40%',
                            },

                            {
                                title: 'Прогнозная дата начала',
                                visible: true,
                                use_component: true,
                                component: FinancialModelForecastStartDate,
                                callBack: this.updateOpportunityList.bind(this),
                                width: '20%',
                            }
                        ]}
                    />
                </Box>
                <Box
                    sx={{
                        height: '600px',
                        overflowY: 'scroll',
                    }}
                >
                    <CommonPaginatedTable
                        title={''}
                        data={this.state.report}
                        fields={[
                            {
                                title: 'Дата',
                                name: 'date',
                                visible: true,
                                preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value)
                            },
                            {
                                title: 'Выручка',
                                name: 'certificates',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Выручка (прогноз)',
                                name: 'revenueForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Вх. платеж',
                                name: 'payments',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Вх. платеж (прогноз)',
                                name: 'paymentsForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'УСН',
                                name: 'payment_tax',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'УСН (прогноз)',
                                name: 'payment_taxForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Накладные (выплата)',
                                name: 'overhead_cash_by_date',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'ФОТ - основной',
                                name: 'employee_sum',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'ФОТ - бонус',
                                name: 'employee_bonus_sum',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Налог на ФОТ',
                                name: 'employee_tax',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Остаток',
                                name: 'cash',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                            {
                                title: 'Остаток (прогноз)',
                                name: 'cashForecast',
                                visible: true,
                                preProcess: (value) => (value?numberFormat.format(value):value)
                            },
                        ]}
                    />
                </Box>
            </Stack>
        )
    }
}

export default FinancialReport;