// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import TodayIcon from '@mui/icons-material/Today';


class TaskDetailChangeEndDate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: TodayIcon,
            buttonLabel: 'Смена времени окончания',
            buttonColor: 'success',
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: 'Смена статуса',
            modalIcon: TodayIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: [
                    {
                        'name': 'end_dt',
                        'label': 'Время окончания',
                        'xs': 12,
                        'sm': null,
                        'md': null,
                        'lg': null,
                        'xl': null,
                        'required': true,
                        'type': 'date',
                        'default': this.props.task.end_dt,
                    },
                    {
                        type: 'submit',
                        label: 'Сохранить'
                    }
                ],
                processForm: this.processForm.bind(this),
            },
        }
    }

    async processForm(form){
        const url = `task/${this.props.task.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200){
            this.setModalOpenFalse();
            await this.props.callBack();
        }
    }

}

export default TaskDetailChangeEndDate