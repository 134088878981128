import React from 'react';

// components
import CommonSidebar from "../Shared/CommonSidebar";
import LeadCreateModal from "../Lead/LeadCreateModal";

//icons
import ListIcon from '@mui/icons-material/List';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';


class OpportunitySidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={
                    [
                        {
                            title: 'Лиды',
                            items: [
                                {
                                    type: 'component',
                                    component: <LeadCreateModal />
                                },
                                {
                                    title: 'Нераспределенные',
                                    link: '/lead/waiting/',
                                    icon: QuestionMarkIcon,
                                },
                                {
                                    title: 'Входящие',
                                    link: '/lead/incoming/',
                                    icon: MoveToInboxIcon,
                                },
                                {
                                    title: 'Исходящие ',
                                    link: '/lead/outgoing/',
                                    icon: ForwardToInboxIcon,
                                }
                            ],
                        },
                        {
                            title: 'Сделки',
                            items: [
                                {
                                    title: 'Список',
                                    link: '/opportunity/',
                                    icon: LocalOfferIcon,
                                },
                            ],
                        },
                    ]
                }
            />
        )
    }
}

export default OpportunitySidebar
