// components
import OpportunityCard from "../Opportunity/OpportunityCard";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import WebAssetIcon from "@mui/icons-material/WebAsset";


class FinancialModelOpportunityCardWrapper extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: WebAssetIcon,
            buttonLabel: '',
            buttonColor: 'neutral',
            ready: true,
        })
    }

    async beforeModalOpen(){
        return {
            modalLabel: '',
            modalIcon: null,
            mainComponent: OpportunityCard,
            mainComponentProps: {
                opportunity: this.props.componentObj.opportunity,
                comments: this.props.componentObj.comments,
                delivery_states: this.props.componentObj.delivery_states,
                my_roles: this.props.componentObj.my_roles,
            },
        }
    }
}

export default FinancialModelOpportunityCardWrapper