import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, } from "@mui/joy";
import withRouter from "../../utils/withRouter";

// components
import CommonInfoTab from "../Shared/CommonInfoTab";

// icons
import InfoIcon from "@mui/icons-material/Info";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import Grid from "@mui/joy/Grid";
import VacationCreateModal from "../Vacation/VacationCreateModal";
import dayjs from "dayjs";


class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriber: null,
            employee: null,
            vacations: null,
            vacationsPage: 1,
            vacationsPages: null,
            bonuses: null,
            bonusesPage: 1,
            bonusesPages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveSubscriber();
        await this.retrieveEmployee();
        await this.retrieveVacations();
        await this.retrieveBonuses();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveSubscriber();
            await this.retrieveEmployee();
            await this.retrieveVacations();
            await this.retrieveBonuses();
        }
    }

    async retrieveSubscriber() {
        this.setState(
            {
                subscriber: null
            }
        );
        const url = `subscriber/me`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                subscriber: response.data
            }
        );
    }

    async retrieveEmployee() {
        this.setState(
            {
                employee: null
            }
        );
        const url = `employee/me`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                employee: response.data
            }
        );
    }

    async retrieveVacations(page=1) {
        this.setState(
            {
                vacations: null
            }
        );
        const url = `vacation/my`;
        const params = {
            page: page
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                vacations: response.data.results,
                vacationsPages: response.data.total_pages,
                vacationsPage: page,
            }
        );
    }

    async retrieveBonuses(page=1) {
        this.setState(
            {
                bonuses: null
            }
        );
        const url = `employee_bonus/my`;
        const params = {
            page: page
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                bonuses: response.data.results,
                bonusesPages: response.data.total_pages,
                bonusesPage: page,
            }
        );
    }

    render() {
        if (!this.state.subscriber) {
            return (<CircularProgress/>)
        }
        if (!this.state.employee) {
            return (<CircularProgress/>)
        }
        if (this.state.vacations === null) {
            return (<CircularProgress/>)
        }
        if (this.state.bonuses === null) {
            return (<CircularProgress/>)
        }
        return (
            <Grid
                container={true}
                spacing={2}
            >
                <Grid xs={12} md={12}>
                    <CommonInfoTab
                        // callBack={this.props.callBack}
                        groups={
                            [
                                {
                                    title: 'Общая информация',
                                    icon: <InfoIcon/>,
                                    fields: [
                                        {
                                            title: 'Фамилия',
                                            value: this.state.employee.last_name,
                                            xs: 12,
                                            sm: 12,
                                            md: 4,
                                            lg: 4,
                                        },
                                        {
                                            title: 'Имя',
                                            value: this.state.employee.first_name,
                                            html: true,
                                            xs: 12,
                                            sm: 12,
                                            md: 4,
                                            lg: 4,
                                        },
                                        {
                                            title: 'Отчество',
                                            value: this.state.employee.patronymic,
                                            html: true,
                                            xs: 12,
                                            sm: 12,
                                            md: 4,
                                            lg: 4,
                                        },
                                    ]
                                }
                            ]
                        }
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <CommonPaginatedTable
                                title={'Бонусы'}
                                data={this.state.bonuses}
                                pages={this.state.bonusesPages}
                                pageNumber={this.state.bonusesPage}
                                pagesCallback={this.retrieveBonuses.bind(this)}
                                fields={[
                                    {
                                        title: 'Сумма',
                                        name: 'salary',
                                        visible: true,
                                    },
                                    {
                                        title: 'Дата выплаты',
                                        name: 'pay_date',
                                        visible: true,
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value),
                                    },
                                    {
                                        title: 'Комментарий',
                                        name: 'description',
                                        visible: true,
                                    },
                                ]
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <CommonPaginatedTable
                                title={'Отпуски'}
                                data={this.state.vacations}
                                pages={this.state.vacationsPages}
                                pageNumber={this.state.vacationsPage}
                                pagesCallback={this.retrieveVacations.bind(this)}
                                createComponent={VacationCreateModal}
                                createComponentProps={{}}
                                fields={[
                                    {
                                        title: 'Дата начала',
                                        name: 'start_date',
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value),
                                        visible: true,
                                    },
                                    {
                                        title: 'Дата окончания',
                                        name: 'end_date',
                                        preProcess: (value) => (value?dayjs(value).format('DD MMM YYYY'):value),
                                        visible: true,
                                    },
                                    {
                                        title: 'Замещающий',
                                        name: 'replacement_subscriber.user.first_name',
                                        visible: true,
                                    },
                                    {
                                        title: '',
                                        name: 'replacement_subscriber.user.last_name',
                                        visible: true,
                                    },
                                    {
                                        title: 'Согласовано',
                                        name: 'is_approved',
                                        visible: true,
                                    },
                                ]
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

export default withRouter(Profile);
