import React from "react";
import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Chip from "@mui/joy/Chip";


class LeadDetailHeader extends React.Component {
    render() {
        return(
            <Stack
                direction={'row'}
                spacing={2}
            >
                <Typography level={'h4'}>
                    {this.props.lead.title}
                </Typography>
                <Chip
                    color={((value) => {
                        switch (value){
                            case 'OPEN':
                                return 'warning'
                            case 'CLOSE':
                                return 'success'
                            case 'LOST':
                                return 'danger'
                            default:
                                return 'primary'
                        }
                    })(this.props.lead.status)}
                    sx={{
                        mr: 1
                    }}
                >
                    {this.props.lead.status_human}
                </Chip>
            </Stack>
        )
    }
}

export default LeadDetailHeader