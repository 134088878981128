// components
import CommonForm from "../../Shared/CommonForm";
import BaseApi from "../../../utils/BaseApi";
import CommonActionModal from "../../Shared/CommonActionModal";

// icons
import EditIcon from '@mui/icons-material/Edit';
import TableViewIcon from "@mui/icons-material/TableView";


class DeliveryConditionUpdate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: EditIcon,
            buttonLabel: '',
            buttonColor: 'default',
            ready: true,
        })
    }

    async beforeModalOpen() {
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Редактирование условия',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `delivery_stage_condition/update_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }


    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        const url = `delivery_stage_condition/${this.props.obj.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        if (response.status === 200) {
            await this.props.callBack();
            await this.setModalOpenFalse();
        }
    }
}

export default DeliveryConditionUpdate
