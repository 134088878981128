import React from 'react';
import RenderIfVisible from 'react-render-if-visible'

import BaseApi from "../../utils/BaseApi";
import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary, CircularProgress, Stack} from "@mui/joy";
import Avatar from "@mui/joy/Avatar";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import DeliveryCard from "./DeliveryCard";
import Chip from "@mui/joy/Chip";


// icons
import BoltIcon from '@mui/icons-material/Bolt';
import LoopIcon from '@mui/icons-material/Loop';
import DoneAllIcon from '@mui/icons-material/DoneAll';


class DeliveryTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveries: null,
        };
    }

    async componentDidMount() {
        await this.retrieveDeliveries();
    }

    async retrieveDeliveries() {
        this.state = {
            deliveries: null,
        };
        const url = `opportunity/${this.props.opportunity.id}/deliveries/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                deliveries: response.data,
            }
        );
    }

    render() {
        if (!this.state.deliveries) {
            return (<CircularProgress/>)
        }
        return(
            <AccordionGroup
                variant="plain"
                transition="0.2s"
            >
                <Accordion
                    defaultExpanded
                >
                    <AccordionSummary sx={{pt: 2, pb: 2}}>
                        <Avatar color="warning">
                            <BoltIcon />
                        </Avatar>
                        <ListItemContent
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Typography level="title-md">
                                Ждут моего действия
                            </Typography>
                            <Chip
                                color={'warning'}
                                sx={{
                                    ml: 2
                                }}
                            >
                                {this.state.deliveries.my.length}
                            </Chip>
                        </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.state.deliveries.my.length===0?'Список пуст':null}
                        <Stack spacing={2}>
                            {this.state.deliveries.my.map(
                                each => {
                                    return (
                                        <RenderIfVisible stayRendered={true} key={'delivery-card-' + each}>
                                            <DeliveryCard
                                                delivery={each}
                                                callBack={this.retrieveDeliveries.bind(this)}
                                            />
                                        </RenderIfVisible>
                                    )
                                })}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary sx={{pt: 2, pb: 2}}>
                        <Avatar color="primary">
                            <LoopIcon />
                        </Avatar>
                        <ListItemContent
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Typography level="title-md">
                                В работе
                            </Typography>
                            <Chip
                                color={'primary'}
                                sx={{
                                    ml: 2
                                }}
                            >
                                {this.state.deliveries.go.length}
                            </Chip>
                        </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.state.deliveries.go.length===0?'Список пуст':null}
                        <Stack spacing={2}>
                            {this.state.deliveries.go.map(
                                each => {
                                    return (
                                        <RenderIfVisible stayRendered={true} key={'delivery-card-' + each}>
                                            <DeliveryCard
                                                delivery={each}
                                                callBack={this.retrieveDeliveries.bind(this)}
                                            />
                                        </RenderIfVisible>
                                    )
                                })}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary sx={{pt: 2, pb: 2}}>
                        <Avatar color="success">
                            <DoneAllIcon />
                        </Avatar>
                        <ListItemContent
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Typography level="title-md">
                                Завершенные
                            </Typography>
                            <Chip
                                color={'success'}
                                sx={{
                                    ml: 2
                                }}
                            >
                                {this.state.deliveries.completed.length}
                            </Chip>
                        </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.state.deliveries.completed.length===0?'Список пуст':null}
                        <Stack spacing={2}>
                            {this.state.deliveries.completed.map(
                                each => {
                                    return (
                                        <RenderIfVisible stayRendered={true} key={'delivery-card-' + each.id}>
                                            <DeliveryCard
                                                delivery={each}
                                                callBack={this.retrieveDeliveries.bind(this)}
                                            />
                                        </RenderIfVisible>
                                    )
                                })}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </AccordionGroup>
        )
    }
}

export default DeliveryTab;