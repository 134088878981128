import React from "react";
import {Step, StepButton, StepIndicator, Stepper} from "@mui/joy";

// icons
import {Check} from "@mui/icons-material";


class CommonStepper extends React.Component {
    render() {
        return(
            <Stepper
                sx={{
                    width: '100%',
                    mb: 2,
                }}
            >
                {this.props.steps.map((step, index) => (
                    <Step
                        key={step}
                        onClick={
                            async () => {if(this.props.activeStep >= index){await this.props.callBack(index)}}
                        }
                        indicator={
                            <StepIndicator
                                variant={'solid'}
                                color={this.props.activeStep < index ? 'neutral' : 'success'}
                            >
                                {this.props.activeStep <= index ? index + 1 : <Check/>}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                                ...(this.props.activeStep > index &&
                                    index !== 2 && {bgcolor: 'primary.solidBg'}),
                            },
                        }}
                    >
                        <StepButton>{step.title}</StepButton>
                    </Step>
                ))}
            </Stepper>
        )
    }
}

export default CommonStepper
