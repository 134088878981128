// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class IdeaCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: 'Добавить',
            buttonColor: 'primary',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление идеи',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `idea_node/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.parent = this.props.parent;
        if (this.props.x) {
            formDataObj.x_coordinate = this.props.x + 300;
        }
        if (this.props.y) {
            formDataObj.y_coordinate = this.props.y + 300;
        }
        const url = `idea_node/?root=${this.props.root}`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            if (typeof this.props.callBack == 'function') {
                await this.props.callBack();
            }
            await this.setModalOpenFalse();
        }
    }
}

export default IdeaCreate
