// components
import CommonForm from "../../Shared/CommonForm";
import BaseApi from "../../../utils/BaseApi";
import CommonActionModal from "../../Shared/CommonActionModal";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableViewIcon from "@mui/icons-material/TableView";


class DeliveryConditionCreate extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: AddCircleIcon,
            buttonLabel: '',
            buttonColor: 'success',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let formFields = await this.retrieveFields()
        return {
            modalLabel: 'Добавление условия',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: formFields,
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveFields(){
        const url = `delivery_stage_condition/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return  response.data
    }


    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.stage = this.props.stageId;
        const url = `delivery_stage_condition/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.setModalOpenFalse();
            await this.props.callBack();
        }
    }
}

export default DeliveryConditionCreate
