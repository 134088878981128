import React from 'react';
import CommonSidebar from "../Shared/CommonSidebar";

//icons
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BugReportIcon from '@mui/icons-material/BugReport';
import SupportIcon from '@mui/icons-material/Support';


class FeatureRequestSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'Техническая поддержка',
                        items: [
                            {
                                title: 'Идеи',
                                link: '/feature_request/idea/',
                                icon: LightbulbIcon,
                            },
                            {
                                title: 'Баги',
                                link: '/feature_request/bug/',
                                icon: BugReportIcon,
                            },
                            {
                                title: 'Тех. помощь',
                                link: '/feature_request/tech/',
                                icon: SupportIcon,
                            },
                        ],
                    }
                    ]
                }
            />
        )
    }
}

export default FeatureRequestSidebar;
