// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import CommonActionModal from "../Shared/CommonActionModal";

// icons
import FastForwardIcon from '@mui/icons-material/FastForward';
import TableViewIcon from "@mui/icons-material/TableView";


class TaskDetailNextStatus extends CommonActionModal {
    async componentDidMount() {
        this.setState({
            buttonIcon: FastForwardIcon,
            buttonLabel: 'Смена статуса',
            buttonColor: 'success',
            ready: true,
        })
    }

    async beforeModalOpen(){
        let statuses = await this.retrieveStatuses()
        return {
            modalLabel: 'Смена статуса',
            modalIcon: TableViewIcon,
            mainComponent: CommonForm,
            mainComponentProps: {
                fields: [
                    {
                        'name': 'work_status',
                        'label': 'Следующий статус',
                        'xs': 12,
                        'sm': null,
                        'md': null,
                        'lg': null,
                        'xl': null,
                        'required': true,
                        'default': statuses[0][0],
                        'type': 'select',
                        'options': statuses.map((each) => {return(
                            {'value': each[0], 'label': each[1]}
                        )})
                    },
                    {
                        type: 'submit',
                        label: 'Сохранить'
                    }
                ],
                processForm: this.processForm.bind(this),
            },
        }
    }

    async retrieveStatuses() {
        const url = `task/${this.props.taskId}/next_statuses/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }

    async processForm(form){
        const url = `task/${this.props.taskId}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200){
            await this.props.callBack();
            await this.setModalOpenFalse();
        }
    }
}

export default TaskDetailNextStatus