import React from "react";
import {CircularProgress} from "@mui/joy";

// components
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";
import BaseApi from "../../utils/BaseApi";
import WebinarDetailHeader from "./WebinarDetailHeader";
import WebinarTab from "./WebinarTab";
import WebinarAttendeeTab from "./WebinarAttendeeTab";

// icons
import InfoIcon from "@mui/icons-material/Info";
import PeopleIcon from '@mui/icons-material/People';
import WebinarPresentTab from "./WebinarPresentTab";
import WebinarViewedRecordingTab from "./WebinarViewedRecordingTab";


class WebinarDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webinar: null,
        };
    }

    async componentDidMount() {
        await this.retrieveWebinar();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveWebinar();
        }
    }

    async retrieveWebinar() {
        await this.setState(
            {
                webinar: null
            }
        );
        const url = `webinar/${this.props.params.webinarId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                webinar: response.data
            }
        );
    }

    render() {
        if (!this.state.webinar) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'Webinar'}
                appId={this.state.webinar.id}
                header={{
                    component: WebinarDetailHeader,
                    componentProps: {
                        webinar: this.state.webinar
                    },
                    leftOverflow: {
                        icon: null,
                        title: 'Вебинар',
                        color: 'primary'
                    },

                }}
                defaultTabValue={'info'}
                tabComponents={[
                    {
                        title: 'Информация',
                        value: 'info',
                        icon: InfoIcon,
                        component: <WebinarTab
                            webinar={this.state.webinar}
                            callBack={this.retrieveWebinar.bind(this)}
                        />,
                    },
                    {
                        title: 'Регистрации',
                        value: 'attendees',
                        icon: PeopleIcon,
                        component: <WebinarAttendeeTab
                            webinar={this.state.webinar}
                            callBack={this.retrieveWebinar.bind(this)}
                        />,
                    },
                    {
                        title: 'Посещения',
                        value: 'present',
                        icon: PeopleIcon,
                        component: <WebinarPresentTab
                            webinar={this.state.webinar}
                            callBack={this.retrieveWebinar.bind(this)}
                        />,
                    },
                    {
                        title: 'Просмотр записи',
                        value: 'viewed_recording',
                        icon: PeopleIcon,
                        component: <WebinarViewedRecordingTab
                            webinar={this.state.webinar}
                            callBack={this.retrieveWebinar.bind(this)}
                        />,
                    },
                ]}
            />
        )
    }
}

export default withRouter(WebinarDetail);
